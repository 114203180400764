import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { TableContainer, TableHead, TableBody, Table } from "@mui/material";
import { XreaTableRow } from "./xrea-table-row";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function createData(catg_name, catg_values, type) {
    return { catg_name, catg_values, type };
}

export const XreaTable = ({ rows, getCityIndex, currentDropdown }) => {
    const classes = useStyles();
    const collength = Math.max(
        ...rows.map((el) => el["cols"] && el.type == 1 && el["cols"].length)
    );
    return (
        <TableContainer style={{ height: "70vh", overflowY: "scroll" }}>
            <Table
            // className={classes.table}
            // style={{ tableLayout: "fixed" }}
            // stickyHeader
            >
                <TableHead>
                    {rows &&
                        Array.isArray(rows) &&
                        rows.length > 0 &&
                        rows.map((row, index) => {
                            if (index === 0) {
                                return (
                                    <XreaTableRow
                                        key={index}
                                        rowData={row}
                                        noOfCol={collength}
                                        getCityIndex={getCityIndex}
                                        currentDropdown={currentDropdown}
                                        index={index}
                                    />
                                );
                            }
                        })}
                </TableHead>
                <TableBody>
                    {rows &&
                        Array.isArray(rows) &&
                        rows.length > 0 &&
                        rows.map((row, index) => {
                            if (index >= 1) {
                                return (
                                    <XreaTableRow
                                        key={index}
                                        rowData={row}
                                        noOfCol={collength}
                                        getCityIndex={getCityIndex}
                                        currentDropdown={currentDropdown}
                                        index={index}
                                    />
                                );
                            }
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
