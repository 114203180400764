import { useContext } from "react";
import { LoadingContext } from "store/loading-context-provider";
import { savedSeacrhCriteria } from "app";
import { GetAttribute } from "shared/utils";

import NoSavedImg from "../../images/NoSaved.svg";
const guestSearches = [
    {
        searchId: 1,
        searchName: `Young Professionals and Couples with Young Kids analysis for Peoria, IL`,
    },
    {
        searchId: 2,
        searchName: `All Uses for Peoria, IL`,
    },
    {
        searchId: 3,
        searchName: `Young Professionals and Couples with Young Kids analysis in multiple locations`,
    },
];
const NoSavedSearches = () => {
    const { handleSpecificSearchResponse } = useContext(LoadingContext);
    const handleGuestSearch = (element) => {
        const searchtype = "GUEST";
        const searchId = GetAttribute(element, "searchId");
        handleSpecificSearchResponse(searchId, searchtype);
    };

    return (
        <>
            <img
                src={NoSavedImg}
                alt="Nothing found logo"
                className="mx-auto"
                width="250"
                height="250"
            />
            <h2 className="font-wide-bold-italic my-5 text-center">
                No saved searches
            </h2>
            <p className="font-regular text-center mt-8 mb-4">
                Try some sample queries:
            </p>

            <div className="flex flex-wrap justify-center">
                {guestSearches.map(({ searchId, searchName }, index) => {
                    return (
                        <div
                            key={index}
                            className="w-fit"
                            style={savedSeacrhCriteria}
                        >
                            <span
                                searchid={searchId}
                                onClick={handleGuestSearch}
                                className="font-regular"
                            >
                                {searchName}
                            </span>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
export default NoSavedSearches;
