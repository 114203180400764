import { savedSeacrhCriteria } from "app";
import { GetAttribute } from "shared/utils";
import { useContext } from "react";
import { LoadingContext } from "store/loading-context-provider";
import firstCardVisual from "../../images/cardVisual.svg";
const guestSearches = [
    {
        searchId: 1,
        searchName: `Young Professionals and Couples with Young Kids analysis for Peoria, IL`,
    },
    {
        searchId: 2,
        searchName: `All Uses for Peoria, IL`,
    },
    {
        searchId: 3,
        searchName: `Young Professionals and Couples with Young Kids analysis in multiple locations`,
    },
];
const InfoCard = ({ children }) => {
    const { handleSpecificSearchResponse } = useContext(LoadingContext);
    const handleGuestSearch = (element) => {
        const searchtype = "GUEST";
        const searchId = GetAttribute(element, "searchId");
        handleSpecificSearchResponse(searchId, searchtype);
    };
    return (
        <>
            <div className="w-full flex rounded-xl shadow-lg p-10">
                <div className="w-1/3">
                    <img src={firstCardVisual} alt="" />
                </div>
                <div className="w-2/3 ">
                    <h1 className="font-wide-bold mb-8">
                        Instantly Characterize Markets using XREA’s breakthrough
                        algorithms, knowledge base and AI technology.
                    </h1>
                    {/* <Box sx={{ display: { xs: "none", md: "block" } }}> */}
                    <p className="font-regular mb-4">
                        Try some sample searches:
                    </p>

                    <div className="flex flex-wrap">
                        {guestSearches.map(
                            ({ searchId, searchName }, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="w-fit"
                                        style={savedSeacrhCriteria}
                                    >
                                        <span
                                            searchid={searchId}
                                            onClick={handleGuestSearch}
                                            className="font-regular"
                                        >
                                            {searchName}
                                        </span>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default InfoCard;
