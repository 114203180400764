import { createTheme, styled, useTheme } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";

export const AppStyle = createTheme({
    palette: {
        primary: {
            main: "#042458",
            light: "#E8F3FD",
        },
        secondary: {
            main: "#00447B",
        },
        tableHeader: {
            main: "#7BBFBA",
        },
    },

    typography: {
        fontFamily: ["IBM Plex Sans"],
        secondaryFont: "IBM Plex Mono",
        h2: {
            fontWeight: 700,
        },
        button: {
            // Here is where you can customise the button

            fontWeight: 700,
        },
        th: {
            fontWeight: 700,
        },
        TableCell: {
            th: {
                fontWeight: 700,
            },
        },
    },
    MuiTable: {
        styleOverrides: {
            root: {
                padding: "8px",
                backgroundColor: "#CDCAC6",
            },
        },
    },
    overrides: {
        MUIDataTable: {
            responsiveStacked: {
                maxHeight: "none",
                overflowX: "auto",
            },
        },
    },
});

// disable class
export const disableStyle = {
    pointerEvents: "none",
    opacity: 0.4,
};
// buttons container start
export const buttonContainer = {
    flexDirection: "row",
    justifyContent: "space-between",
    mt: 2,
};
// buttons container end
// small message box start
export const smallMessageBox = {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 5px 0px #0000001A",
    fontFamily: '"pf-grand-gothik-variable", "sans-serif"',
    fontVariationSettings: '"ital" 0, "wdth" 100, "wght" 500',

    color: "#A9A9A9",
    padding: "10px",
    borderRadius: "3px",
    color: "#3E3E3E",
};
export const smallMessageBoxArrow = {
    background: "#fff",

    // width: "0",
    // height: "0",
    // borderStyle: "solid",
    // borderWidth: "5px 10px 5px 0",
    // borderColor: "transparent #000 transparent transparent",
    // boxShadow: "0px 0px 5px 0px #0000001A",
};
// small message box end

// horizontal and vertical centering
export const centerAbsoluteItem = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
};

// Dialog start

export const dialog = {
    width: "100vw",
    height: "100vh",
    ...centerAbsoluteItem,
    background: "rgba(13, 91, 219, 0.3)",
    backdropFilter: "blur(5px)",

    "& .MuiPaper-elevation ": {
        width: "66VW",
        maxWidth: "1106px",
        borderRadius: "10px",
    },
};

// Dialog end
export const input = {
    width: "100%",
};
// loader start
export const loaderContainer = {
    ...centerAbsoluteItem,
    zIndex: 1301,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
};

export const backgroundOverLay = {
    zIndex: 1300,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "fixed",
    // background: "rgba(26, 67, 119, 0.5)",
    backgroundColor: "white",
    opacity: "0.85",
    backdropFilter: "blur(5px)",
};
// loader end

export const button3 = {
    bgcolor: "#F43D4F",
    borderRadius: "18px",
    color: AppStyle.palette.common.white,
    "&:hover": {
        bgcolor: "#F43D4F",
    },
};

export const secondarybtn = {
    height: "fit-content",
    textTransform: "none",
    bgcolor: AppStyle.palette.secondary.main,
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: "107.5%",
};
// Button type end

// header start

export const headerItemsContainer = {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
};
export const headerRightContiner = {
    flexDirection: "row",
    alignItems: "center",
    display: { xs: "none", sm: "flex" },
};
// header end

// home page start

export const imageBoxcard2 = {
    height: { xs: "35.5vw", md: "100%" },
};
export const savedSeacrhCriteria = {
    color: "rgba(168, 168, 168, 1)",
    fontWeight: "500",
    backgroundColor: "rgba(249, 249, 249, 1)",
    margin: "0 8px 8px 0",
    padding: "5px 15px",
    borderRadius: "4px",
    cursor: "pointer",
};
// home page end

export const Label = styled("label")`
    padding: 0 0 4px;
    line-height: 1.5;
    display: block;
    font-size: 1rem;
`;

export const InputWrapper = styled("div")(
    ({ theme }) => `
    width: 100%;
    border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    border-radius: 4px;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
    min-height: 54px;
    &:hover {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
      box-shadow: 0px 0px 5px 0px #3478D6 inset;

    }
  
    &.focused {
    border-color: #40a9ff;
    box-shadow: 0px 0px 5px 0px #3478D6 inset;

    }
    &.invalid {
        border-color: #9F3B27;
        box-shadow: 0px 0px 5px 0px #9F3B27 inset;

    }
    & input {
      background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
      color: ${
          theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "#000"
      };
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0;
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
    }
  `
);

export const Listbox = styled("ul")(
    ({ theme }) => `
    width: 41%;
    margin: 2px 0 0;
    padding: 10px;
    position: absolute;
    list-style: none;
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px -1px 7px 2px rgba(0, 0, 0, 0.05), inset 0px 0px 5px rgba(0, 0, 0, 0.05);
    z-index: 1;
  
    & li {
      padding: 8px 12px 5px 12px;
      display: flex;
  
      & span {
        flex-grow: 1;
      }
  
      & svg {
        color: transparent;
      }
    }
  
    & li[aria-selected='true'] {
      background-color: ${
          theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"
      };
      font-weight: 600;
  
      & svg {
        color: #1890ff;
      }
    }
  
    & li.${autocompleteClasses.focused} {
      background-color: ${
          theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"
      };
      cursor: pointer;
  
      & svg {
        color: currentColor;
      }
    }
  `
);
// Auto complete end

// Search view start

export const searchSavedList = {
    height: "33px",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "0.5px solid #A9A9A9",
    height: "fit-content",
    padding: "2px",
    width: "100%",
};

// save search start
export const confirmationBoxposition = {
    position: "absolute",
    right: "15px",
    top: "-103px",
};
// save search end

// Search reasult section start

export const tablesContainter = {
    width: "100%",
    height: "70vh",
    overflowY: "auto",
};
// Search reasult section end

// Search  section start

export let searchContainter = {
    width: "100%",
    boxShadow: "none",
};

// Card Style start

export const card1style = {
    padding: { xs: "8.6%", md: "2.8%" },
    boxShadow: "0px -4px 17px -3px rgba(0, 0, 0, 0.15)",

    borderRadius: "10px",
    width: "100%",
    zIndex: "99",
    padding: "25px",
};
const cardBgStyle = {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
};
export const card2style = {
    padding: { xs: "8.6%", md: "2.8%" },
    boxShadow: "0px -4px 17px -3px rgba(0, 0, 0, 0.15)",

    borderRadius: "20px",
    position: "relative",
};
export const card2BuronContainer = {
    flexDirection: "row",
    mt: 1,
    justifyContent: { xs: "center", md: "left" },
    alignItems: { xs: "end", md: "left" },
};
// Card Style end

// Table style start

export const cellSize = {
    width: "300px",
};
const headerCellSize = {
    ...cellSize,
};
export const blankTableCell = {
    ...headerCellSize,
    p: 2,
};
const tablecellCommon = {
    ...blankTableCell,
    fontWeight: 700,
    justifyContent: "center",
    alignItems: "center",
};

export const stickyHeaderCell = {
    backgroundColor: AppStyle.palette.common.white,
    zIndex: 1,
};
export const tableHeader = {
    ...tablecellCommon,
    ...headerCellSize,
    p: 2,
    justifyContent: "end",
    fontSize: "1.25rem",
    textAlign: "right",
    marginLeft: "auto",
    fontFamily: AppStyle.typography.fontFamily[0],
    bgcolor: AppStyle.palette.common.white,
};

export const tableUseStyle = {
    table: {
        minWidth: 650,
    },
    sticky: {
        position: "sticky",
        left: 0,
    },
    tabcol: {
        borderRadius: "5px 5px 0px 0px",
        boxShadow: "2px 0px 10px 0px #eeeeee",
        margin: "0px 5px 0px 5px",
    },
    tabcol1: {
        borderRadius: "5px 5px 0px 0px",
        boxShadow: "2px 0px 10px 0px #eeeeee",
    },
    tabcol3: {
        backgroundColor: "#1ca2aa",
    },
};
export const tableHeaderTooltipContainer = {
    justifyContent: "right",
    alignItems: "center",
    ml: 1,
    flexDirection: "row",
};
// Table style end

// xrea section start

// xrea section end

// xrea market study start
export const xreaMaretStudy = {
    ...card2style,
    ...cardBgStyle,
    bgcolor: "#8190BB",
    opacity: "29%",
};
// xrea market study end
// market segmnent start

export const boxStyle = {
    width: "61px",
    height: "61px",
    margin: "auto",
    cursor: "pointer",
};
const marketSegmentCommonStyle = {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "107.5%",
    mt: 1,
    bgcolor: AppStyle.palette.common.white,
};
export const marketSegmentHeading = {
    ...marketSegmentCommonStyle,
    textAlign: "center",
};
export const marketSegmentHeaderStyle = {
    ...marketSegmentCommonStyle,
    textAlign: "right",
};
// market segmnent end
export const imageBoxStackContainers = {
    flexDirection: "row",
    alignItems: "end",
    height: "194px",
    justifyContent: { xs: "center", md: "end" },
};
const itemTableStyle = {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "34px",
    fontSize: "2rem",
    marginLeft: ".8rem",
    color: "#fff",
    borderRadius: "7px",
};
export const itemTableStatusStyle = {
    ...itemTableStyle,
    height: "5vh",
    width: "7vh",
};
export const itemTablePercentileStyle = {
    ...itemTableStyle,
    height: "5vh",
    width: "9.8vh",
};

export const radioStyle = {
    p: 0,
    marginLeft: "9px",
};
