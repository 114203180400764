const Button = ({
    onClick,
    disabled,
    children,
    className,
    style,
    fullWidth,
}) => {
    return (
        <button
            className={`relative button font-regular ${className}`}
            onClick={onClick}
            disabled={disabled}
            style={{ ...style, width: fullWidth ? "100%" : "" }}
        >
            {children}
        </button>
    );
};
export default Button;
