import { makeStyles } from "@mui/styles";
import { lighten, Stack, TableCell, TableRow } from "@mui/material";
import {
    AppStyle,
    itemTablePercentileStyle,
    itemTableStatusStyle,
    tableUseStyle,
} from "app";
import React from "react";

export const SegmentUsecaseTableRow = ({ rowData, currentDropdown }) => {
    const useStyles = makeStyles(tableUseStyle);
    const classes = useStyles();
    return (
        <>
            {rowData?.map(
                ({ use_case_group_desc, groupData, use_case_color }, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell
                                className={classes.sticky}
                                component="td"
                                align="right"
                                style={{
                                    paddingLeft: 0,
                                    backgroundColor: "#fff",
                                }}
                            >
                                <span className="font-medium">
                                    {use_case_group_desc}
                                </span>
                            </TableCell>
                            {groupData &&
                                groupData.map(({ value, isMax }, mapIndex) => {
                                    let bgcolor = isMax
                                        ? lighten(use_case_color, 0.9)
                                        : AppStyle.palette.common.white;
                                    const {
                                        index,
                                        percentile,
                                        grade,
                                        percentileChipBgcolor,
                                        statusChipBgcolor,
                                    } = value;

                                    let chipBgColor = statusChipBgcolor;
                                    let chipValue = grade;
                                    let sx = itemTableStatusStyle;
                                    if (currentDropdown === "P") {
                                        chipBgColor = percentileChipBgcolor;
                                        chipValue = `${percentile}`;
                                        sx = itemTablePercentileStyle;
                                    }
                                    return (
                                        <TableCell
                                            key={mapIndex}
                                            align="center"
                                        >
                                            <Stack>
                                                <Stack
                                                    flexDirection={"row"}
                                                    justifyContent={"center"}
                                                    alignItems={"center"}
                                                >
                                                    <Stack
                                                        flexDirection={"row"}
                                                        justifyContent={
                                                            "center"
                                                        }
                                                        alignItems={"center"}
                                                        className="table-data-text"
                                                    >
                                                        {index}
                                                        <Stack
                                                            sx={{
                                                                ...sx,
                                                                bgcolor:
                                                                    chipBgColor,
                                                            }}
                                                            style={{
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "20px",
                                                                height: "20px",
                                                                width: "40px",
                                                                borderRadius:
                                                                    "3px",
                                                            }}
                                                            className="font-extended-x-bold"
                                                        >
                                                            {chipValue}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </TableCell>
                                    );
                                })}
                            <TableCell className="table-col-placeholder" />
                        </TableRow>
                    );
                }
            )}
        </>
    );
};
