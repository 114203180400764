import { SignIn } from "@clerk/clerk-react";

import bgSrc from "../../images/welcome/login.jpg";
import quoteSrc from "../../images/welcome/quote.svg";
const Login = () => {
    return (
        <div className="w-full flex">
            <div
                style={{
                    background: `url( ${bgSrc} ) center / cover`,
                }}
                className=" relative min-h-screen	w-1/3 px-10 py-20"
            >
                <div
                    style={{ background: "rgba(21, 101, 216, 0.9)" }}
                    className="absolute top-0 left-0 w-full h-full z-0"
                ></div>
                <img
                    src="/playground_assets/logo.svg"
                    alt=""
                    className="relative"
                />
                <div className="relative mt-20">
                    <img src={quoteSrc} alt="" className="mb-6" />
                    <p className="font-regular text-white welcome-description mb-2">
                        The XREA team performed a very detailed market and
                        feasibility study that helped us decide the right uses
                        for our property.
                    </p>
                    <p className="font-regular text-white welcome-description">
                        In addition, they provided the specific details that are
                        often lacking from normal vendors. Two thumbs up!
                    </p>
                    <p className="font-regular text-right text-white mb-2">
                        Hamza Odeh
                    </p>
                    <p className="font-regular text-right text-white">
                        Restoration Nation, TX
                    </p>
                </div>
            </div>
            <div className="min-h-screen w-2/3 p-20">
                <div className="p-10">
                    <h1 className="font-welcome-title">Welcome to XREA!</h1>
                    <p className="font-regular text-neutral-400	">
                        Get started with a free 7-day trial. No credit card
                        required.
                    </p>
                </div>
                <SignIn
                    afterSignInUrl="/"
                    afterSignUpUrl="/welcome"
                    appearance={{
                        elements: {
                         footerActionText: {
                          fontSize: "20px",
                          paddingTop: "20px"
                         },
                            footerActionLink: {
                             fontSize: "40px",
                             paddingLeft: "15px",
                             paddingTop: "px"
                            },
                            formFieldLabel: {
                             fontSize: "20px",
                            },
                            formButtonPrimary: {
                             fontSize: "18px",
                            },
                        },
                    }}
                ></SignIn>
            </div>
        </div>
    );
};
export default Login;
