import bgSrc from "../../images/welcome/plan.jpg";
import planIcon from "../../images/welcome/planIcon.svg";
import planIconSelected from "../../images/welcome/planIconSelected.svg";
import Button from "shared/components/Button";
import { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { xreaRequest } from "server/utils/axios";
import { setClerkMetadataSubscriptionSuccess } from "server/api/stripe";

const plans = [
  {
    title: "Monthly",
    description: "",
    price: "$30",
    period: "per month",
  },
  {
    title: "Annual",
    description: "",
    price: "$300",
    period: "charged annually",
  },
];
const Plan = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  let [sessionId, setSessionId] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSessionId(query.get("session_id"));
      const payLoad = {
        clerkUserId: user.id,
        sessionId: sessionId,
      };
      if (sessionId !== "") {
        setClerkMetadataSubscriptionSuccess(payLoad).then((res) => {
          user.reload();
          navigate("/");
        });
      }
    }

    // if (query.get("canceled")) {
    //   setSuccess(false);
    //   setMessage(
    //     "Order canceled -- continue to shop around and checkout when you're ready."
    //   );
    // }
  }, [sessionId]);

  async function createCheckoutSession(lookup_key) {
    const payLoad = {
      lookup_key: lookup_key,
      customer_id: user.publicMetadata.stripeCustomerId,
    };
    const url = `/api/stripe/create-checkout-session`;
    const data = await xreaRequest(url, "POST", payLoad);
    window.location.href = data.data;
  }

  const [selectedPlan, setSelectedPlan] = useState("Annual");
  return (
    <div className="w-full flex">
      <div
        style={{
          background: `url( ${bgSrc} ) center / cover`,
        }}
        className=" relative min-h-screen	w-1/3 px-10 py-20"
      >
        <div
          style={{ background: "rgba(21, 101, 216, 0.9)" }}
          className="absolute top-0 left-0 w-full h-full z-0"
        ></div>
        <img src="/playground_assets/logo.svg" alt="" className="relative" />
        <div className="relative mt-20">
          <p className="font-regular text-white mt-20 welcome-description mb-2">
            Monthly XREA subscribers will receive the discount on either (1)
            Market or Feasibility Study, valid until 90-days on your
            subscription end date.
          </p>
          <p className="font-regular text-white  welcome-description mb-2">
            Annual subscribers receive (3) Market and Feasibility Studies at the
            discounted price valid within 12 months of the subscription start
            date.
          </p>
        </div>
      </div>
      <div className="min-h-screen w-2/3 p-20">
        <div className="p-10">
          <h1 className="font-welcome-title mb-6">
            Unlock the full power of XREA
          </h1>

          {plans.map((item) => (
            <div
              className={`plan-card relative p-10 flex mb-10 cursor-pointer ${
                selectedPlan === item.title ? "selected" : ""
              }`}
              onClick={() => setSelectedPlan(item.title)}
              key={item.title}
            >
              <div className="pr-8">
                {selectedPlan === item.title ? (
                  <img src={planIconSelected} alt="" />
                ) : (
                  <img src={planIcon} alt="" />
                )}
              </div>
              <div className="w-full">
                <h2 className="font-extended-bold mb-2">{item.title}</h2>
                <p
                  className="font-regular text-neutral-500"
                  style={{
                    fontSize: "14px",
                    lineHeight: "14px",
                  }}
                >
                  {item.description}
                </p>
                <div className="flex items-start mt-5">
                  <h3 className="font-extended-bold">{item.price}</h3>{" "}
                  <span
                    className="font-regular text-neutral-500"
                    style={{
                      fontSize: "14px",
                      lineHeight: "14px",
                    }}
                  >
                    {item.period}
                  </span>
                </div>
              </div>
            </div>
          ))}
          <div className="flex justify-end">
            <Button
              onClick={() =>
                createCheckoutSession(
                  selectedPlan === "Monthly" ? "monthly" : "yearly"
                )
              }
              className="btn--wide"
            >
              Subscribe
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Plan;
