import { useState } from "react";

import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import bgSrc from "../../images/welcome/welcome.jpg";
import checked from "../../images/checkboxChecked.svg";
import Button from "shared/components/Button";

import mgvgSrc from "../../images/welcome/clientLogos/mgvg_living.png";
import neboLawSrc from "../../images/welcome/clientLogos/nebo_law.png";
import restorationSrc from "../../images/welcome/clientLogos/restoration_nation.png";
import wabashSrc from "../../images/welcome/clientLogos/wabash_associates.png";
import { createTrial } from "../../server/api/stripe";

const Welcome = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("1-10");
  const [phone, setPhone] = useState("");

  const [isNewsletter, setIsNewsletter] = useState(false);
  const [isTnC, setIsTnC] = useState(false);

  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [isTnCValid, setIsTnCValid] = useState(true);

  const validateCompanyName = () => {
    const isValid = companyName.trim().length >= 2;
    setIsCompanyNameValid(isValid);
    return isValid;
  };
  const validateTnC = () => {
    const isValid = isTnC;
    setIsTnCValid(isValid);
    return isValid;
  };
  const toggleNewsletter = () => {
    setIsNewsletter(!isNewsletter);
  };

  const toggleTnC = () => {
    setIsTnC(!isTnC);
    setIsTnCValid(true);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const isCompanyValid = validateCompanyName();
    const isTnCValid = validateTnC();

    if (isCompanyValid && isTnCValid) {
      //Backend call to update private Metadata then navigate to /
      createTrial({
        clerkUserId: user.id,
        companyName: companyName,
        companySize: companySize,
      }).then(() => {
        user.reload();
        navigate("/");
      });
    }
  };
  return (
    <div className="w-full flex">
      <div
        style={{
          background: `url( ${bgSrc} ) center / cover`,
        }}
        className=" relative min-h-screen	w-1/3 px-10 py-20"
      >
        <div
          style={{ background: "rgba(21, 101, 216, 0.9)" }}
          className="absolute top-0 left-0 w-full h-full z-0"
        ></div>
        <img src="/playground_assets/logo.svg" alt="" className="relative" />
        <div className="relative mt-20 mb-4">
          <p className="font-regular text-white mt-20 welcome-description">
            We're passionate about having local development done by local firms.
            We're here for you.{" "}
          </p>
        </div>
        <div className="flex flex-wrap w-full relative">
          <img src={mgvgSrc} alt="" className="w-14 my-2 mr-4" />
          <img src={neboLawSrc} alt="" className="w-14 my-2 mr-4" />
          <img src={restorationSrc} alt="" className="w-14 my-2 mr-4" />
          <img src={wabashSrc} alt="" className="w-14 my-2" />
        </div>
      </div>
      <div className="min-h-screen w-2/3 p-20">
        <div className="p-10">
          <h1 className="font-welcome-title">Let’s get acquainted </h1>
          <p className="font-regular text-neutral-400	">
            Tell us a little bit about yourself so we can make the product
            better for you.
          </p>
          <form onSubmit={submitHandler} className="mt-6">
            <div className="w-4/6">
              <label htmlFor="companyName" className="form-label">
                Your Company Name
              </label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                className={`form-input ${isCompanyNameValid ? "" : "invalid"}`}
                value={companyName}
                onChange={(event) => {
                  setIsCompanyNameValid(true);
                  setCompanyName(event.target.value);
                }}
              />
              <label htmlFor="companySize" className="form-label">
                Company Size
              </label>
              <select
                type="text"
                id="companySize"
                name="companySize"
                className="form-input"
                value={companySize}
                onChange={(event) => {
                  setCompanySize(event.target.value);
                }}
              >
                <option>1</option>
                <option>2-3</option>
                <option>4-6</option>
                <option>7-10</option>
                <option>11-15</option>
                <option>15+</option>
              </select>
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number (optional)
              </label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                className="form-input"
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
              />
              <div className="flex items-center mb-4">
                <div className="form-checkbox">
                  <input
                    type="checkbox"
                    checked={isNewsletter}
                    onChange={toggleNewsletter}
                    name="newsletter"
                    id="newsletter"
                    // style={{ display: "none" }}
                  />
                  <div
                    className={`custom-checkbox ${
                      isNewsletter ? "checked" : ""
                    }`}
                    onClick={toggleNewsletter}
                  >
                    {isNewsletter && <img src={checked} alt="Checked" />}
                  </div>
                </div>
                <label htmlFor="newsletter" className="checkbox-label">
                  I’d like to get occasional data reports and trends to my
                  mailbox.
                </label>
              </div>
              <div className="flex items-center">
                <label htmlFor="termsAndConditions">
                  <div className="flex items-center">
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        checked={isTnC}
                        onChange={toggleTnC}
                        name="termsAndConditions"
                        id="termsAndConditions"
                        // style={{ display: "none" }}
                      />
                      <div
                        className={`custom-checkbox ${isTnC ? "checked" : ""} ${
                          isTnCValid ? "" : "required"
                        }`}
                        onClick={toggleTnC}
                      >
                        {isTnC && <img src={checked} alt="Checked" />}
                      </div>
                    </div>
                    <label
                      htmlFor="termsAndConditions"
                      className="checkbox-label"
                    >
                      I agree with XREA’s{" "}
                      <a href="https://xrea.ai/app-tos" target="_blank">
                        Terms & Conditions
                      </a>
                    </label>
                  </div>
                </label>
              </div>
            </div>
            <div className="flex justify-end pr-10 mt-10">
              <Button className="btn--wide">Start using XREA</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Welcome;
