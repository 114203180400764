import { Box, Stack } from "@mui/material";
import { backgroundOverLay, loaderContainer } from "app";
import React from "react";

const OverlayLayout = ({ children }) => {
    const styles = `

        /* 
        VARIABLES
        */
        :root{
            --xrea-border-radius: 5px;

            --xrea-chip-blue: #4591F0;

            --xrea-score-bv: #AB5A8A;
            --xrea-score-or: #467F7B;
            --xrea-score-ma: #877555;
            --xrea-score-cwyk: #8190BB;
            --xrea-score-yp: #00479D;
            --xrea-score-r: #8166D6;
        }
        .loading-state-wrapper {
            margin-bottom: 32px;
        }
        .loading-bar {
            display: inline-block;
            width: 12px;
            height: 50px;
            border-radius: 4px;
            margin-left: 2px;
            margin-right: 2px;
            animation: loading 1s ease-in-out infinite;
        }

        .loading-bar:nth-child(1) {
            background-color: var(--xrea-score-bv);
            animation-delay: 0;
        }
        
        .loading-bar:nth-child(2) {
            background-color: var(--xrea-score-cwyk);
            animation-delay: 0.09s;
        }
        
        .loading-bar:nth-child(3) {
            background-color: var(--xrea-score-ma);
            animation-delay: 0.18s;
        }
        
        .loading-bar:nth-child(4) {
            background-color: var(--xrea-score-r);
            animation-delay: 0.27s;
        }
        
        .loading-bar:nth-child(5) {
            background-color: var(--xrea-score-or);
            animation-delay: 0.42s;
        }
        
        .loading-bar:nth-child(6) {
            background-color: var(--xrea-score-yp);
            animation-delay: 0.57s;
        }
        
        @keyframes loading {
            0% {
                transform: scale(1);
            }
            20% {
                transform: scale(1, 2.2);
            }
            40% {
                transform: scale(1);
            }
        }
    `;
    return (
        <>
            <Box sx={backgroundOverLay}></Box>
            <Box sx={loaderContainer}>
                <div className="loading-state-wrapper">
                    <div className="loading-bar"></div>
                    <div className="loading-bar"></div>
                    <div className="loading-bar"></div>
                    <div className="loading-bar"></div>
                    <div className="loading-bar"></div>
                    <div className="loading-bar"></div>
                </div>

                {children}
                <style>{styles}</style>
            </Box>
        </>
    );
};

export default OverlayLayout;
