import { Box, Grid, Stack } from "@mui/material";
import { searchSavedList, confirmationBoxposition, smallMessageBox } from "app";

import { React, useEffect, useState, useContext } from "react";
import { dateFilter, GetAttribute, updateLocalStorage } from "shared/utils";
import { getSavedSearch } from "server/api/saved-search";
import { LoadingContext } from "store/loading-context-provider";
import { deleteSearch } from "server/api/delete-search";
import { useUser } from "@clerk/clerk-react";
import { OpenLink } from "../../shared/utils";
import Button from "shared/components/Button";
import CloseIcon from "../../images/✕.svg";
import NoSavedSearches from "shared/components/NoSavedSearches";
const SavedSearches = () => {
    const { searchTitleGetterSetter, userGetterSetter, setSaveSearchId } =
        useContext(LoadingContext);
    const { setSearchTitle } = searchTitleGetterSetter;
    const { isSignedIn, user, isLoaded } = useUser();
    const [searchList, setSearchList] = useState(null);
    const userId = user?.id;

    const setSearchedData = async () => {
        const data = (await getSavedSearch(userId)).data;
        let { maxSavedLength, savedList } = data.response;
        // todo make another function
        savedList = savedList.map((item) => {
            item.deleteConfirmationOpen = false;
            return item;
        });

        setSearchList(savedList);
        const noOfsearch = savedList.length;
        updateLocalStorage("xrea", { maxSavedLength, noOfsearch });
    };
    useEffect(() => {
        if (isLoaded && isSignedIn) {
            setSearchedData();
        }
    }, [isLoaded, isSignedIn]);

    const openModel = async (element) => {
        const currentIndex = GetAttribute(element, "index");
        let confirmationUpdatedSearchList = searchList.map((item, index) => {
            if (currentIndex == index) {
                item.deleteConfirmationOpen = true;
            } else {
                item.deleteConfirmationOpen = false;
            }
            return item;
        }, currentIndex);
        setSearchList(confirmationUpdatedSearchList);
    };
    const handleDelete = async (searchId) => {
        const payLoad = {
            searchId,
        };
        await deleteSearch(payLoad);
        await setSearchedData();
    };
    const handleClose = (element) => {
        const updatedSearchList = searchList.map((item) => {
            item.deleteConfirmationOpen = false;
            return item;
        });
        setSearchList(updatedSearchList);
    };
    const handleSearch = (element) => {
        const searchId = GetAttribute(element, "searchid");
        const searchTitle = GetAttribute(element, "searchtitle");
        setSearchTitle(searchTitle);
        setSaveSearchId(searchId);
    };
    const goToPricing = () => {
        OpenLink("https://xrea.ai/xrea-base-license/");
    };
    return (
        <div className="rounded-xl shadow-md p-10 w-full">
            {searchList?.length > 0 && (
                <h2 className="page-title mb-5">Saved Searches</h2>
            )}
            {searchList?.length > 0 ? (
                searchList.map(
                    (
                        {
                            save_search_title,
                            created_on,
                            deleteConfirmationOpen,
                            save_search_id,
                        },
                        index
                    ) => {
                        return (
                            <Stack
                                key={index}
                                sx={{
                                    ...searchSavedList,
                                    position: "relative",
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        spacing={1}
                                        item
                                        xs={10}
                                        searchid={save_search_id}
                                        searchtitle={save_search_title}
                                        onClick={handleSearch}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <Grid item xs={5}>
                                            <p className="font-bold">
                                                {save_search_title}
                                            </p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <p
                                                className="font-regular"
                                                style={{
                                                    color: "#A9A9A9",
                                                }}
                                            >
                                                {dateFilter(created_on)}
                                            </p>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Stack
                                            alignItems={"end"}
                                            justifyContent={"center"}
                                            height={"100%"}
                                        >
                                            <button
                                                style={{
                                                    justifyContent: "end",
                                                    width: "fit-content",
                                                    padding: 0,
                                                }}
                                                index={index}
                                                onClick={openModel}
                                            >
                                                <img src={CloseIcon} alt="" />
                                            </button>
                                        </Stack>
                                        {deleteConfirmationOpen && (
                                            <Box sx={confirmationBoxposition}>
                                                <Box sx={smallMessageBox}>
                                                    <span
                                                        className="block font-bold mb-4"
                                                        style={{
                                                            color: "#555555",
                                                            fontSize: "18px",
                                                            lineHeight: "23px",
                                                        }}
                                                    >
                                                        Delete this saved
                                                        search?
                                                    </span>
                                                    <div className="flex justify-between">
                                                        <button
                                                            index={index}
                                                            onClick={
                                                                handleClose
                                                            }
                                                            className="font-italic"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <Button
                                                            style={{
                                                                backgroundColor:
                                                                    "#F43D4F",
                                                                borderRadius:
                                                                    "10px",
                                                                minHeight:
                                                                    "unset",
                                                                padding:
                                                                    "8px 16px",
                                                                fontSize:
                                                                    "13px",
                                                                lineHeight:
                                                                    "16px",
                                                            }}
                                                            // searchId={
                                                            //     save_search_id
                                                            // }
                                                            onClick={() => {
                                                                handleDelete(
                                                                    save_search_id
                                                                );
                                                            }}
                                                            className="font-bold"
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </Box>
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </Stack>
                        );
                    }
                )
            ) : (
                <NoSavedSearches />
            )}
        </div>
    );
};

export default SavedSearches;
