import { React, useContext, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Box } from "@mui/system";
import { OpenLink, updateLocalStorage } from "shared/utils";
import InfoCard from "shared/components/InfoCard";
import {
  GetAttribute,
  getLocalStorageItem,
  setLocalStorageItem,
} from "shared/utils";
import {
  TextField,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import { input, radioStyle, secondarybtn, tablesContainter } from "app";
import AlertDialog from "shared/utils/dialog/alert-dialog";
import { useState } from "react";
import { postSearchDetails } from "server/api/save-search";
import { LoadingContext } from "store/loading-context-provider";
import BrightTooltip from "shared/utils/tooltip/bright-tooltiip";
import { XreaTable } from "shared/utils/data-table/xrea-table";
import SearchSection from "./SearchSection";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import Button from "shared/components/Button";
import SavedSearches from "./SavedSearches";
import InfoPoint from "shared/components/InfoPoints/InfoPoint";
import welcomeImgSrc from "../../images/infoPoints/welcome-xrea.svg";
import researchImgSrc from "../../images/infoPoints/research-next-step.svg";
import scoreImgSrc from "../../images/infoPoints/score-illustration.png";

const infoPointsData = [
  {
    id: 1,
    hasOnClick: false,
    imageSrc: welcomeImgSrc,
    title: "Welcome to XREA!",
    content: (
      <p className="font-book mb-8">
        We know using a new tool can be confusing, but we aim to make our
        product experience seamless!
      </p>
    ),
    btn: { title: "Take a tour", url: "https://xrea.ai/welcome/tour" },
    isLoggedIn: true,
  },
  {
    id: 2,
    hasOnClick: true,
    imageSrc: researchImgSrc,
    title: "Ready to take the next step in your research?",
    content: (
      <p className="font-book mb-8">
        A Market Study will help you confirm the exact use or location, and
        target customer for your project by ensuring there is sufficient demand.
        A Feasibility Study will help you refine the specs and financial returns
        of your project.
      </p>
    ),
    btn: {
      title: "Order a Study",
      url: "",
      secondTitle: "Learn More",
      secondUrl: "https://xrea.ai/studies",
    },
    isLoggedIn: true,
  },
  {
    id: 3,
    hasOnClick: false,
    imageSrc: scoreImgSrc,
    title: "What is an XREA score?",
    content: (
      <>
        <p className="font-book mb-5">
          Our Scores measure the ability of the given market to sustain the
          proposed use and customer type. We generate these scores for hundreds
          of markets, making it simple for you to rank and compare your
          projects.
        </p>
        <p className="font-book mb-8">
          Learn more about our different score types, their definitions, and the
          additional market segments we generate.
        </p>
      </>
    ),
    btn: {
      title: "Browse XREA Handbook",
      url: "https://xrea.ai/handbook",
    },
    isLoggedIn: true,
  },
];

const SearchResult = () => {
  const {
    loaderFunction,
    handleResponseMessage,
    searchTitleGetterSetter,
    searchedReasult,
    cityNameResultList,
    xreSearchDisable,
    setXreSearchDisable,
    xreaTableRows,
    xreaSeachButtonTitle,
    setXreSearchButtonTitle,
    setSearchCriteria,
    setMarketSegmentData,
    hasResult,

    setCityNameResultList,
    setSearchedReasult,
    setSelectedCityList,
    setSelectedUseCaseList,
    setCityList,
    setXreaTableRows,
  } = useContext(LoadingContext);

  const selectedDropdownStyle = {
    display: "inline-block",
    marginLeft: "10px",
    fontSize: "18px",
    lineHeight: "18px",
    paddingTop: "3px",
  };
  const showDropdown = [
    {
      value: "G",
      label: "Grade",
      dropdownHeading: (
        <span style={selectedDropdownStyle} className="font-wide-x-bold">
          XREA Score
        </span>
      ),
    },
    {
      value: "P",
      label: "Percentile score",
      dropdownHeading: (
        <span style={selectedDropdownStyle} className="font-bold">
          Score percentile
        </span>
      ),
    },
  ];

  const firstDropDownElement = showDropdown[0];

  const { searchTitle, setSearchTitle } = searchTitleGetterSetter;
  const { startLoader, stopLoader } = loaderFunction;
  const [showDialog, setShowDialog] = useState(false);
  const [searchName, setSearchName] = useState();
  // const [userId, setUserId] = useState();
  const [noOfsearch, setNoOfsearch] = useState(
    getLocalStorageItem("xrea")?.data?.noOfsearch
  );
  const [radioVisible, setRadioVisible] = useState(false);
  const [saveSearchInputError, setSaveSearchInputError] = useState();
  const [selectedValue, setSelectedValue] = useState(
    firstDropDownElement.value
  );
  const [dropdownHeading, setDropdownHeading] = useState(
    firstDropDownElement.dropdownHeading
  );

  const navigate = useNavigate();
  const { usecase } = searchedReasult || {};
  const { isSignedIn, user } = useUser();

  const handleClear = () => {
    setCityNameResultList([]);
    setSearchedReasult();
    setSelectedCityList([]);
    setSelectedUseCaseList([]);
    setCityList([]);
    setSearchCriteria();
    setXreaTableRows([]);
    setSearchTitle();
    updateLocalStorage("xrea", { isdisabled: false });
  };

  useEffect(() => {
    const logdata = getLocalStorageItem("xrea")?.data;
    // const maxSavedLength = logdata?.maxSavedLength;
    const maxSavedLength = 999;
    // const userId = isLogin();
    // const isdisabled = logdata?.isdisabled;
    const isdisabled = false;
    // setUserId(userId);
    if (isSignedIn) {
      if (noOfsearch >= maxSavedLength) {
        setXreSearchButtonTitle("Saved searches limit exceeded");
        setXreSearchDisable(true);
      } else {
        setXreSearchButtonTitle("Save search");
        setXreSearchDisable(isdisabled);
      }
    } else {
      setXreSearchButtonTitle("Save search");
      setXreSearchDisable(true);
    }
    return () => {
      setSearchTitle();
    };
  }, [noOfsearch]);
  useEffect(() => {
    setSearchCriteria();
  }, []);

  const getCityIndex = (e) => {
    const indexValue = GetAttribute(e, "indexid");
    // const selectedResultRow = searchedReasult.general_stat.data[indexValue];
    const selectedMarketSegmentRow =
      searchedReasult.marketSegment.data[indexValue];
    // const { tenYearPopGrowthRate, medianIncome, homePToIncome } =
    //     selectedResultRow;
    const { clusterName, cluster_desc } = selectedMarketSegmentRow;
    // const cityName = cityNameResultList[indexValue].name;
    // navigate("/market_segment");
    console.log(clusterName);
    OpenLink(`https://xrea.ai/${clusterName.replace(" ", "-").toLowerCase()}`);
    // setMarketSegmentData({
    //     cityName,
    //     tenYearPopGrowthRate,
    //     medianIncome,
    //     homePToIncome,
    //     clusterName,
    //     cluster_desc,
    // });
  };

  const handleDropdownClick = () => {
    setRadioVisible(!radioVisible);
  };
  const handleRadioChange = ({ target }) => {
    const selectedDropdownValue = target.value;
    setSelectedValue(selectedDropdownValue);
    const { dropdownHeading } = showDropdown.find(
      ({ value }) => value === selectedDropdownValue
    );
    setDropdownHeading(dropdownHeading);
    handleDropdownClick();
  };
  const openDialog = () => {
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
  };
  const openSaveSearch = () => {
    openDialog();
  };
  const saveSearch = async () => {
    setSaveSearchInputError();
    if (searchName) {
      startLoader();
      const xreaData = getLocalStorageItem("xrea")?.data;

      // const {userId} = xreaData.loginData;
      const citits = cityNameResultList.map(({ name, id }) => {
        return {
          geographic_area_name: name,
          geoId: id,
        };
      });
      const usecases = usecase.label.map(
        ({ use_case_group, use_case_group_desc, use_case_color }) => {
          return {
            code: use_case_group,
            name: use_case_group_desc,
            color: use_case_color,
          };
        }
      );
      const payLoad = {
        name: searchName,
        user_id: user?.id,
        city: citits,
        usecase: usecases,
      };

      const { data } = await postSearchDetails(payLoad);
      stopLoader();
      const { code, message } = data;
      if (code === 200) {
        closeDialog();
        handleResponseMessage(message);
        let noOfSavedSearch = data.saveSearchCount;

        const noOfSearch = Number(noOfSavedSearch);
        setLocalStorageItem("xrea", { ...xreaData, noOfsearch });
        setNoOfsearch(noOfSearch);
      } else {
        setSaveSearchInputError(message);
      }
    } else {
      setSaveSearchInputError("Enter a search title");
    }
  };

  const AlertDialogProps = {
    title: "Save XREA search",
    dialogContent: (
      <TextField
        error={saveSearchInputError}
        helperText={saveSearchInputError}
        id="date"
        placeholder="Save search as..."
        type="text"
        sx={input}
        className="save-search-input"
        onChange={(e) => setSearchName(e.target.value)}
        value={searchName}
        InputLabelProps={{
          shrink: true,
        }}
      />
    ),
    dialogAction: {
      button1: (
        <Button
          onClick={closeDialog}
          style={{
            minWidth: "160px",
            background: "transparent",
            color: "#CDCDCD",
          }}
          className="font-bold"
        >
          Cancel
        </Button>
      ),
      button2: (
        <Button
          onClick={saveSearch}
          style={{ minWidth: "160px" }}
          className="font-bold"
        >
          Save
        </Button>
      ),
      closeDialog: closeDialog,
    },
    actionsOnUnMount: function () {
      setSearchName();
      setSaveSearchInputError();
    },
  };

  const xreaTooltipTitle =
    "You have exceeded the limit of saved searches. Please delete older searches before saving new ones.";
  return (
    <div className="max-w-screen-xl flex flex-wrap mx-auto gap-8 mb-32 -translate-y-16 rounded-xl bg-white">
      {isSignedIn ? null : <InfoCard />}
      <SearchSection />

      {xreaTableRows && xreaTableRows.length === 0 && <SavedSearches />}
      {xreaTableRows && xreaTableRows.length > 0 ? (
        <>
          <div className="w-full flex justify-between items-center mb-5">
            <h2 className="page-title">
              {searchTitle ? searchTitle : "Search results"}
            </h2>
            <div className="flex items-center">
              <div className="relative mr-8">
                <FormControl>
                  <div
                    onClick={handleDropdownClick}
                    style={{
                      borderRadius: "5px",
                      padding: "0 10px",
                      height: "40px",
                      boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                    }}
                    className="flex items-center"
                  >
                    <p
                      style={{
                        display: "inline-block",
                        paddingTop: "3px",
                      }}
                      className="font-regular"
                    >
                      Showing:
                    </p>
                    {dropdownHeading}
                  </div>
                  {radioVisible && (
                    <FormControl
                      style={{
                        position: "absolute",
                        top: "100%",
                        zIndex: "99",
                        background: "#fff",
                        width: "100%",
                        padding: "10px",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px",
                      }}
                    >
                      <span className="font-bold" style={{ fontSize: "16px" }}>
                        Select your default view:
                      </span>
                      <RadioGroup
                        name="radioButtons"
                        value={selectedValue}
                        onChange={handleRadioChange}
                        className="font-book "
                      >
                        {showDropdown &&
                          showDropdown.length > 0 &&
                          showDropdown.map(({ label, value }, index) => (
                            <FormControlLabel
                              key={index}
                              value={value}
                              className="dropdown-radio-label"
                              control={<Radio size={"12px"} sx={radioStyle} />}
                              label={label}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                </FormControl>
              </div>
              {xreSearchDisable && isSignedIn ? (
                <BrightTooltip
                  title={xreaTooltipTitle}
                  placement="bottom"
                  arrow
                >
                  <span>
                    <Button
                      variant="contained"
                      sx={secondarybtn}
                      onClick={openSaveSearch}
                      disabled={xreSearchDisable}
                    >
                      {xreaSeachButtonTitle}
                    </Button>
                  </span>
                </BrightTooltip>
              ) : (
                <div className="flex items-center">
                  {hasResult && (
                    <Button
                      onClick={() => {
                        if (isSignedIn) {
                          navigate("/");
                          handleClear();
                        }
                      }}
                      style={{
                        marginRight: "10px",
                        boxShadow: "0px 0px 5px 0px #0000001A",
                        background: "#fff",
                        color: "#000000",
                        paddingTop: "10px",
                      }}
                    >
                      Clear search
                    </Button>
                  )}

                  <Button
                    onClick={openSaveSearch}
                    disabled={xreSearchDisable}
                    className="font-wide-x-bold"
                    style={{ paddingTop: "8px" }}
                  >
                    {xreaSeachButtonTitle}
                  </Button>
                </div>
              )}
            </div>
          </div>

          <Box sx={tablesContainter}>
            <ScrollContainer>
              <XreaTable
                rows={xreaTableRows}
                getCityIndex={getCityIndex}
                currentDropdown={selectedValue}
              />
            </ScrollContainer>
          </Box>
          {showDialog && <AlertDialog props={AlertDialogProps} />}
        </>
      ) : null}
      <div className="w-full grid grid-cols-2 gap-8">
        {infoPointsData.map((item) => (
          <InfoPoint
            key={item.id}
            imageSrc={item.imageSrc}
            title={item.title}
            content={item.content}
            btn={item.btn}
            hasOnClick={item.hasOnClick}
          />
        ))}
      </div>
    </div>
  );
};
export default SearchResult;
