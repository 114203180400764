import Button from "../Button";
import iconSrc from "../../../images/infoPoints/trial-icon.svg";
import { useNavigate } from "react-router-dom";

const FreeTrialCard = ({ trialEnd }) => {
    const navigate = useNavigate();
    //take trialEnd timestamp and calculate remaining days
    const trialEndData = new Date(trialEnd * 1000);
    const remainingDays = Math.floor(
        (trialEndData - Date.now()) / (1000 * 60 * 60 * 24)
    );

    const initChoosePlan = () => {
        navigate("/plan");
    };

    return (
        <div className="rounded-xl shadow-md p-10 w-full free-trial-card">
            <img
                src={iconSrc}
                alt=""
                className="mb-8 mx-auto"
                style={{ userSelect: "none" }}
            />
            <h2 className="font-wide-bold-italic mb-5">
                You have {remainingDays} days remaining in your free XREA trial.
            </h2>
            <p className="font-book mb-5">
                We take pertinent demand and supply data, and apply econometric
                techniques and real estate expertise to deliver decision-ready
                insights.
            </p>
            <p className="font-book mb-8">
                We’ve aggregated the highest quality data and applied our
                algorithms on nearly every city and zip-code in the United
                States.
            </p>
            <div className="flex justify-center	 w-full">
                <Button onClick={() => navigate("/plan")} className="btn--wide">
                    Choose your plan
                </Button>
            </div>
        </div>
    );
};

export default FreeTrialCard;
