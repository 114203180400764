import {
  ClerkLoaded,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
} from "@clerk/clerk-react";
import { Stack } from "@mui/material";
import { headerItemsContainer, headerRightContiner } from "app";
import SavedSearches from "modules/search/SavedSearches";
import SearchResult from "modules/search/SearchResult";
import SearchSection from "modules/search/SearchSection";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  findOrCreateCustomerId,
  getStripeSubscriptionActive,
} from "server/api/stripe";
import FreeTrialCard from "shared/components/InfoPoints/FreeTrialCard";
import InfoPoint from "shared/components/InfoPoints/InfoPoint";
import headerbg from "../../images/headerBg.png";
import HomeGuest from "./HomeGuest";
import PhoneNotSupported from "./PhoneNotSupported";
// Info Points Images
import researchImgSrc from "../../images/infoPoints/research-next-step.svg";
import scoreImgSrc from "../../images/infoPoints/score-illustration.png";
import welcomeImgSrc from "../../images/infoPoints/welcome-xrea.svg";
import { OpenLink } from "shared/utils";

const infoPointsData = [
  {
    id: 1,
    hasOnClick: false,
    imageSrc: welcomeImgSrc,
    title: "Welcome to XREA!",
    content: (
      <p className="font-book mb-8">
        We know using a new tool can be confusing, but we aim to make our
        product experience seamless!
      </p>
    ),
    btn: { title: "Take a tour", url: "https://xrea.ai/welcome/tour" },
    isLoggedIn: true,
  },
  {
    id: 2,
    hasOnClick: false,
    imageSrc: researchImgSrc,
    title: "Ready to take the next step in your research?",
    content: (
      <p className="font-book mb-8">
        A Market Study will help you confirm the exact use or location, and
        target customer for your project by ensuring there is sufficient demand.
        A Feasibility Study will help you refine the specs and financial returns
        of your project.
      </p>
    ),
    btn: {
      title: "Order a Study",
      url: "https://xrea.ai/studies",
      secondTitle: "Learn More",
      secondUrl: "https://xrea.ai/studies",
    },
    isLoggedIn: true,
  },
  {
    id: 3,
    hasOnClick: false,
    imageSrc: scoreImgSrc,
    title: "What is an XREA score?",
    content: (
      <>
        <p className="font-book mb-5">
          Our Scores measure the ability of the given market to sustain the
          proposed use and customer type. We generate these scores for hundreds
          of markets, making it simple for you to rank and compare your
          projects.
        </p>
        <p className="font-book mb-8">
          Learn more about our different score types, their definitions, and the
          additional market segments we generate.
        </p>
      </>
    ),
    btn: {
      title: "Browse XREA Handbook",
      url: "https://xrea.ai/handbook",
    },
    isLoggedIn: true,
  },
];
const HomeView = () => {
  const { isSignedIn, user } = useUser();
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [trialActive, setTrialActive] = useState(false);
  const [trialEnd, setTrialEnd] = useState(null);
  const [subscriptionOpen, setSubscriptionOpen] = useState(false);
  const excludeRoutes = ["/login", "/welcome", "/plan"];
  const shouldRenderHeader = !excludeRoutes.includes(location.pathname);
  const navigate = useNavigate();

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 920px)").matches
  );
  const handleLogin = () => {
    navigate("/login");
  };

  const openStripeCustomer = () => {
   OpenLink(process.env.REACT_APP_STRIPE_MANAGEMENT_ADDRESS);
  }

  useEffect(() => {
    if (isSignedIn) {
      user.reload().then((res) => {
        if (!user.publicMetadata.welcomeDataSent) {
          navigate("/welcome");
        }
        const payLoad = {
          clerkUserId: user.id,
        };
        findOrCreateCustomerId(payLoad).then((res) => {
          const payLoad = {
            clerkUserId: user.id,
          };
          getStripeSubscriptionActive(payLoad).then((res) => {
            setSubscriptionActive(res);
            user.reload();
            if (!res && user.publicMetadata.trialUsed) {
              navigate("/plan");
            }
            if (user.publicMetadata.trialActive) {
              setTrialActive(true);
            }
          });
        });
        setTrialEnd(user.publicMetadata.trialEnd);
      });
    }
  }, [isSignedIn]);

  useEffect(() => {
    window
      .matchMedia("(min-width: 920px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <>
      <Helmet>
        <title>XREA</title>
      </Helmet>
      {shouldRenderHeader && (
        <>
          <div
            className="header flex items-center"
            style={{ backgroundImage: `url(${headerbg})` }}
          >
            <div className="container mx-auto  max-w-screen-xl  flex items-center justify-between">
              <div>
                <img
                  src="/playground_assets/logo.svg"
                  alt="XREA"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ cursor: "pointer" }}
                  width="173"
                  height="38"
                />
              </div>

              <div className="flex items-center justify-end" on>
                <div onClick={openStripeCustomer} className="w-[140px] h-[30px] mr-2  bg-gray-200 rounded-xl text-center pt-1 hover:border-gray-300 hover:border-2"> Manage Subscription </div>
                <div className="flex items-center">
                  <ClerkLoaded>
                    <SignedOut>
                      <img
                        src="/playground_assets/user-placeholder.svg"
                        className="mr-4"
                      />
                    </SignedOut>
                  </ClerkLoaded>
                  <UserButton
                    afterSignOutUrl="/"
                    appearance={{
                      elements: {
                        userButtonPopoverCard: {
                          "margin-left": "200px",
                        },
                      },
                    }}
                  />
                  <div className="flex items-center">
                    {user && (
                      <h3 className="text-white font-book ml-4">
                        {user.fullName}
                      </h3>
                    )}
                    <div
                      className="flex flex-col justify-center  ml-4"
                      onClick={handleLogin}
                      style={{ cursor: "pointer" }}
                    >
                      {!user && (
                        <>
                          <h2 className="text-white font-bold">
                            Welcome to XREA!
                          </h2>
                          <h3 className="text-white font-book">
                            Log In / Sign Up
                          </h3>
                        </>
                      )}
                    </div>
                    <SignedIn>
                      {isSignedIn ? null : <div>Please sign in</div>}
                    </SignedIn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <Stack sx={headerItemsContainer}>
              {/* XREA LOGO */}
              <Stack sx={headerRightContiner} className="">
                {/* Signed out profile picture */}
              </Stack>
            </Stack>
          </div>
        </>
      )}

      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <SignedOut>
                {matches ? (
                  <div className="container max-w-screen-xl mx-auto">
                    <HomeGuest />
                  </div>
                ) : (
                  <div className="container max-w-screen-xl mx-auto">
                    <PhoneNotSupported />
                  </div>
                )}
              </SignedOut>
              <SignedIn>
                <ClerkLoaded>
                  {matches ? (
                    <div className="container max-w-screen-xl mx-auto">
                      <div className=" flex flex-wrap  gap-8 mb-32 -translate-y-16 ">
                        <SearchSection />
                        <SavedSearches />
                        <div className="w-full grid grid-cols-2 gap-8">
                          {trialActive ? (
                            <FreeTrialCard trialEnd={trialEnd} />
                          ) : (
                            <></>
                          )}
                          {infoPointsData.map((item) => (
                            <InfoPoint
                              key={item.id}
                              imageSrc={item.imageSrc}
                              title={item.title}
                              content={item.content}
                              btn={item.btn}
                              hasOnClick={item.hasOnClick}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="container max-w-screen-xl mx-auto">
                      <HomeGuest />
                    </div>
                  )}
                </ClerkLoaded>
              </SignedIn>
            </>
          }
        />
        <Route
          exact
          path="/search"
          element={
            <ClerkLoaded>
              {matches ? (
                <div className="container max-w-screen-xl mx-auto">
                  <SearchResult />
                </div>
              ) : (
                <div className="container max-w-screen-xl mx-auto">
                  <PhoneNotSupported />
                </div>
              )}
            </ClerkLoaded>
          }
        />
      </Routes>
    </>
  );
};
export default HomeView;
