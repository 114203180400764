import React from "react";
import IconSvg from "../../../images/info_icon.svg";
import BrightTooltip from "./bright-tooltiip";

const TooltipHelp = ({ title }) => {
    return (
        <BrightTooltip title={title} placement="right" arrow>
            <img
                src={IconSvg}
                alt="?"
                style={{ marginLeft: "10px" }}
                width={24}
                height={24}
            />
        </BrightTooltip>
    );
};

export default TooltipHelp;
