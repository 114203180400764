import axios from "axios";

if (!process.env.REACT_APP_BACKEND_ADDRESS) {
 throw new Error("Missing Backend Address");
}

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ADDRESS,
});

export const xreaRequest = async (url, type, payLoad) => {
    let response;
    if (type === "POST") {
        response = await api.post(url, payLoad);
    }
    if (type === "GET") {
        response = await api.get(url);
    }
    if (response) {
        const { status, data, message } = response;
        if (status === 200) {
            return { message, data, status };
        } else {
            return { message };
        }
    }
};
