export const message_nodata = "No Data Available";

export const timeout = 3000;

export const tooltip_10YrPopulation =
    "The United States 10-year Population Growth Rate from 2011-2021 was 7.54%";
export const tooltip_medianHouseHold =
    "The United States Median Household Income (in 2021 inflation-adjusted dollars)  is 69,717";
export const tooltip_homePriceToIncome =
    "The United States Home Price to Income Ratio for 2021 is Roughly 3.54";

export const useCaseChipValue = {
    A: "#33A181",
    "A+": "#008A61",
    "A-": "#66B9A0",
    B: "#C7AC48",
    "B+": "#F8D85A",
    "B-": "#AE973F",
    C: "#CC4C33",
    "C+": "#E25439",
    "C-": "#B5432E",
    D: "#712A1C",
    "D+": "#873221",
    "D-": "#5A2116",
    F: "#160805",
    PE: "#555555",
};
