import { OpenLink } from "shared/utils";
import Button from "../Button";
import { React, useContext } from "react";
import { LoadingContext } from "store/loading-context-provider";
import { postMarketStudyMailSent } from "server/api/market-study-mail-sent";
import { useUser } from "@clerk/clerk-react";

const redirect = () => {
  OpenLink("https://xrea.ai/studies");
};

const InfoPoint = ({ imageSrc, title, content, btn, hasOnClick }) => {
  const { user } = useUser();

  const {
    loaderFunction,
    handleResponseMessage,
    hasResult,
    cityNameList,
    useCaseNameList,
  } = useContext(LoadingContext);
  const { startLoader, stopLoader } = loaderFunction;

  const sendMail = async () => {
    const payLoad = {
      clerkUserId: user.id,
      userEmail: user.primaryEmailAddress,
      cityList: cityNameList,
      caseList: useCaseNameList,
    };

    startLoader();
    const status = await postMarketStudyMailSent(payLoad);
    stopLoader();
    if (status === 200) {
      handleResponseMessage("We will get in touch shortly!");
    }
  };

  const handleClick = () => {
    hasResult ? sendMail() : redirect();
  };

  return (
    <div className="rounded-xl shadow-md p-10 w-full">
      <img
        src={imageSrc}
        alt=""
        className="mb-8 mx-auto"
        style={{ userSelect: "none", pointerEvents: "none" }}
      />
      <h2 className="font-wide-bold-italic mb-5">{title}</h2>
      {content}

      <div className="flex justify-center	 w-full">
        {hasOnClick && (
          <Button onClick={() => handleClick()} className="btn--wide">
            {btn.title}
          </Button>
        )}

        {!hasOnClick && (
          <Button onClick={() => OpenLink(btn.url)} className="btn--wide">
            {btn.title}
          </Button>
        )}

        {btn.secondTitle && btn.secondUrl && (
          <Button
            onClick={() => OpenLink(btn.secondUrl)}
            className="ml-2 white-button "
          >
            {btn.secondTitle}
          </Button>
        )}
      </div>
    </div>
  );
};

export default InfoPoint;
