import { React, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { dialog } from "app";
import { Box, Grid } from "@mui/material";
export default function AlertDialog({ props }) {
    const { title, dialogContent, dialogAction, actionsOnUnMount } = props;
    const { button1, button2, closeDialog } = dialogAction;

    useEffect(() => {
        return () => {
            actionsOnUnMount();
        };
    }, []);

    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={dialog}
            onClose={closeDialog}
        >
            <h2 className="page-title" style={{ padding: "20px" }}>
                {title}
            </h2>
            <DialogContent sx={{ py: 0 }}>{dialogContent}</DialogContent>
            <Grid container spacing={1} my={3}>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                    <DialogActions style={{ padding: "0 24px" }}>
                        <Box>{button1}</Box>
                        <Box>{button2}</Box>
                    </DialogActions>
                </Grid>
            </Grid>
        </Dialog>
    );
}
