import { Box, Stack } from "@mui/material";
import { button3, buttonContainer, smallMessageBox } from "app";
import React from "react";
import Button from "shared/components/Button";
const ConfirmationBox = ({ content }) => {
    return (
        <Box sx={smallMessageBox}>
            Delete this saved search?
            <Stack sx={buttonContainer}>
                <Button>Cancel</Button>
                <Button style={button3}>Delete</Button>
            </Stack>
        </Box>
    );
};

export default ConfirmationBox;
