import phoneImg from "../../images/PhoneNotSupported.svg";
const PhoneNotSupported = () => {
    return (
        <div className="mx-auto p-2 -translate-y-16 rounded-xl bg-white">
            <img src={phoneImg} alt="" className="mx-auto block my-10" />
            <h1
                className="font-wide-bold-italic text-center mx-auto block mb-4"
                style={{ color: "#3E3E3E" }}
            >
                Desktop experience
                <br />
                recommended for XREA
            </h1>
            <p className="font-book text-center block">
                For the best experience using XREA, please access{" "}
                <strong>app.xrea.ai</strong> from your desktop computer. We're
                working to enhance compatibility with mobile browsers. Thank you
                for your understanding!
            </p>
        </div>
    );
};
export default PhoneNotSupported;
