import { Box, Stack, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
    AppStyle,
    boxStyle,
    cellSize,
    marketSegmentHeaderStyle,
    marketSegmentHeading,
    stickyHeaderCell,
    tableHeaderTooltipContainer,
    tableUseStyle,
} from "app";
import { SegmentUsecaseTableRow } from "./segment-usecase-table-row";
import TooltipHelp from "../tooltip/tooltip-help";
import { getClusterImage } from "../associate/get-clusterImage";

export const XreaTableRow = ({
    rowData,
    noOfCol,
    getCityIndex,
    currentDropdown,
    index,
}) => {
    const useStyles = makeStyles(tableUseStyle);
    const classes = useStyles();
    let colRow = new Array(noOfCol).fill(null);
    const headingRow = {
        "& th": {
            p: 0,
        },
    };
    return (
        <>
            {rowData && rowData.type === 1 && (
                <TableRow key={rowData.type}>
                    {rowData.cols.map((v, i) => {
                        return (
                            <TableCell
                                key={i}
                                align="center"
                                className={
                                    i === 0 ? classes.sticky : classes.tabcol
                                }
                                style={{
                                    backgroundColor: "#fff",
                                    ...cellSize,
                                }}
                            >
                                {i > 0 ? (
                                    <div className="th-inner">
                                        <h4 className="font-bold-italic">
                                            {v}
                                        </h4>
                                    </div>
                                ) : (
                                    <h4 className="font-bold-italic">{v}</h4>
                                )}
                            </TableCell>
                        );
                    })}
                    <TableCell className="table-col-placeholder" />
                </TableRow>
            )}
            {rowData && rowData.type === 2 && (
                <TableRow
                    className={classes.sticky}
                    sx={stickyHeaderCell}
                    key={rowData.type}
                >
                    {colRow.map((v, i) => {
                        let marketSegmenetTextSx = marketSegmentHeading;
                        let cellTitle;
                        let clusterImageName;
                        let imageUrl;
                        if (i === 0) {
                            marketSegmenetTextSx = marketSegmentHeaderStyle;
                            cellTitle = rowData.cols[i];
                        } else {
                            const { clusterName, clusterCode } =
                                rowData.cols[i];
                            cellTitle = clusterName;
                            clusterImageName = getClusterImage(clusterCode);
                            imageUrl = `/playground_assets/clusterImages/${clusterImageName}`;
                        }

                        return (
                            <TableCell
                                key={i}
                                align="center"
                                className={i === 0 && classes.sticky}
                                style={{
                                    paddingLeft: 0,
                                    backgroundColor: "#fff",
                                }}
                            >
                                {i > 0 && (
                                    <Box
                                        sx={boxStyle}
                                        indexid={i - 1}
                                        onClick={getCityIndex}
                                    >
                                        <img
                                            src={imageUrl}
                                            alt={clusterImageName}
                                        />
                                    </Box>
                                )}
                                <h4
                                    className={`font-bold ${
                                        i === 0
                                            ? "text-right"
                                            : "mt-1 cursor-pointer"
                                    }`}
                                    indexid={i - 1}
                                    onClick={getCityIndex}
                                >
                                    {cellTitle}
                                </h4>
                            </TableCell>
                        );
                    })}
                    <TableCell className="table-col-placeholder" />
                </TableRow>
            )}
            {rowData && rowData.type === 3 && (
                <TableRow
                    key={rowData.type}
                    sx={{
                        ...headingRow,
                        backgroundColor: AppStyle.palette.tableHeader.main,
                    }}
                    className={classes.tabcol3}
                    style={{
                        minHeight: "40px",
                        maxHeight: "40px",
                        height: "40px",
                    }}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        colSpan={1}
                        className={classes.sticky}
                    >
                        <h3 className="font-wide-bold table-head">
                            {rowData.cols[0]}
                        </h3>
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        colSpan={noOfCol - 1}
                    ></TableCell>
                    <TableCell className="table-col-placeholder" />
                </TableRow>
            )}
            {rowData &&
                rowData.type === 4 &&
                rowData.cols &&
                rowData.cols.map(
                    ({ tableHeaderTitle, toolTip, groupData }, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell
                                    align="center"
                                    className={classes.sticky}
                                    style={{
                                        paddingLeft: 0,
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    <Stack sx={tableHeaderTooltipContainer}>
                                        <p className="font-bold table-td">
                                            {tableHeaderTitle}
                                        </p>

                                        <TooltipHelp title={toolTip} />
                                    </Stack>
                                </TableCell>
                                {groupData.map((v, i) => {
                                    return (
                                        <TableCell
                                            key={i}
                                            align="center"
                                            style={{
                                                paddingLeft: 0,
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            <span className="table-data-text">
                                                {v}
                                            </span>
                                        </TableCell>
                                    );
                                })}
                                <TableCell className="table-col-placeholder" />
                            </TableRow>
                        );
                    }
                )}
            {rowData && rowData.type === 5 && (
                <TableRow
                    key={rowData.type}
                    sx={{
                        ...headingRow,
                        backgroundColor: AppStyle.palette.tableHeader.main,
                    }}
                    className={classes.tabcol3}
                    style={{
                        minHeight: "40px",
                        maxHeight: "40px",
                        height: "40px",
                    }}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        colSpan={1}
                        className={classes.sticky}
                    >
                        <h3 className="font-wide-bold table-head">
                            {rowData.cols[0]}
                        </h3>
                    </TableCell>

                    <TableCell
                        component="th"
                        scope="row"
                        colSpan={noOfCol - 1}
                    ></TableCell>
                    <TableCell className="table-col-placeholder" />
                </TableRow>
            )}
            {/* Use Case Score */}
            {rowData && rowData.type === 6 && (
                <SegmentUsecaseTableRow
                    currentDropdown={currentDropdown}
                    rowData={rowData.cols}
                />
            )}
        </>
    );
};
