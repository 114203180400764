import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { AppStyle } from "./app-style";
import "./app.css";
import HomeView from "modules/home/HomeView";
import LoadingContextProvider from "store/loading-context-provider";
import { ClerkProvider } from "@clerk/clerk-react";
import Login from "modules/login/Login";
import Plan from "modules/login/Plan";
import Welcome from "modules/login/Welcome";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
export const App = () => {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <ThemeProvider theme={AppStyle}>
        <Router>
          <LoadingContextProvider>
            <Routes>
              <Route exact path="*" element={<HomeView />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/welcome" element={<Welcome />} />
              <Route exact path="/plan" element={<Plan />} />

              {/* <HomeView /> */}
            </Routes>
          </LoadingContextProvider>
        </Router>
      </ThemeProvider>
    </ClerkProvider>
  );
};
