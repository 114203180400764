import { xreaRequest } from "server/utils/axios";

export const getStripeSubscription = async (payLoad) => {
  const url = `/api/stripe/subscription`;
  const { data } = await xreaRequest(url, "POST", payLoad);
  return data;
};

export const getStripeSubscriptionActive = async (payLoad) => {
 const url = `/api/stripe/subscriptionActive`;
 const { data } = await xreaRequest(url, "POST", payLoad);
 return data;
};

export const findOrCreateCustomerId = async (payLoad) => {
 const url = `/api/stripe/customerId`;
 const { data } = await xreaRequest(url, "POST", payLoad);
 return data;
};

export const setClerkMetadataSubscriptionSuccess = async (payLoad) => {
 const url = `/api/stripe/setClerkMetadata`;
 const { data } = await xreaRequest(url, "POST", payLoad);
 return data;
}

export const createTrial = async (payLoad) => {
 const url = `/api/stripe/createTrial`;
 const { data } = await xreaRequest(url, "POST", payLoad);
 return data;
}
