import { useContext, useEffect } from "react";
import InfoCard from "shared/components/InfoCard";
import InfoPoint from "shared/components/InfoPoints/InfoPoint";
import { removeLocalStorageItems } from "shared/utils";
import { LoadingContext } from "store/loading-context-provider";
import scoreImgSrc from "../../images/infoPoints/score-illustration.png";
import welcomeImgSrc from "../../images/infoPoints/welcome-xrea.svg";

const infoPointsData = [
    {
        id: 1,
        hasOnClick: false,
        imageSrc: welcomeImgSrc,
        title: "Welcome to XREA!",
        content: (
            <p className="font-book mb-8">
                The core of our algorithms utilizes expert economist and data
                science knowledge. Then only do we apply AI to find patterns in
                our analysis to generate market segments that are simple to
                understand.{" "}
            </p>
        ),
        btn: { title: "Learn More", url: "https://xrea.ai/market-intel" },
        isLoggedIn: true,
    },

    {
        id: 2,
        hasOnClick: false,
        imageSrc: scoreImgSrc,
        title: "What is an XREA score?",
        content: (
            <>
                <p className="font-book mb-5">
                    Our Scores measure the ability of the given market to
                    sustain the proposed use and customer type. We generate
                    these scores for hundreds of markets, making it simple for
                    you to rank and compare your projects.
                </p>
                <p className="font-book mb-8">
                    Learn more about our different score types, their
                    definitions, and the additional market segments we generate.
                </p>
            </>
        ),
        btn: {
            title: "Browse XREA Handbook",
            url: "https://xrea.ai/handbook",
        },
        isLoggedIn: true,
    },
];

const HomeGuest = () => {
    const { userGetterSetter } = useContext(LoadingContext);
    const { setUser } = userGetterSetter;
    useEffect(() => {
        removeLocalStorageItems(["xrea"]);
        setUser(false);
    }, []);

    return (
        <div className="max-w-screen-xl flex flex-wrap mx-auto gap-8 mb-32 -translate-y-16 rounded-xl bg-white">
            <InfoCard />
            <div className="w-full grid grid-cols-2 gap-8">
                {infoPointsData.map((item) => (
                    <InfoPoint
                        key={item.id}
                        imageSrc={item.imageSrc}
                        title={item.title}
                        content={item.content}
                        btn={item.btn}
                        hasOnClick={item.hasOnClick}
                    />
                ))}
            </div>
        </div>
    );
};
export default HomeGuest;
