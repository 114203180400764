import { React } from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import closeSvg from "../../../images/✕.svg";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AppStyle, InputWrapper, Label, Listbox } from "app";

function Tag(props) {
    let { label, onDelete, color, id, ...other } = props;
    color = color ? color : "#4591F0";
    const tagStyle = {
        bgcolor: color,
        border: `1px solid ${color}`,
    };

    const handleDelete = () => {
        onDelete();
    };
    return (
        <Box sx={tagStyle} {...other}>
            <span>{label}</span>
            <img src={closeSvg} onClick={handleDelete} />
        </Box>
    );
}
// Customizing tag style
// background-color: ${theme.palette.mode} === 'dark' ? 'rgba(255,255,255,0.08)' : '#1565c0';
const StyledTag = styled(Tag)(
    ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  border-radius: 4px;
  color: #fff;
  box-sizing: content-box;
  padding: 6px 10px 6px 10px;
  outline: 0;
  overflow: hidden;
  font-variation-settings: "ital" 0, "wdth" 100, "wght" 400;
  font-size: 18px;
  line-height: 18px;
  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 3px;
  }

    & img {
    cursor: pointer;
    margin-left: 5px;
    opacity: 0.5;
    transition: 0.25s ease;
    margin-top: 1px;

    &:hover {
        opacity: 1;        
    }
    
  }
`
);

Tag.propTypes = {
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    // onChange: PropTypes.func.isRequired,
    color: PropTypes.string,
    savedList: PropTypes.array,
};

const AutoCompleteTabList = ({
    getOptionProps,
    groupedOptions,
    fromIndex,
    toIndex,
    selectedList,
}) => {
    return (
        <div>
            {groupedOptions.slice(fromIndex, toIndex).map((option, index) => {
                index = index + fromIndex;
                let bgcolor = AppStyle.palette.primary.light;
                let fontColor = AppStyle.palette.common.black;
                selectedList.map(({ color, code }) => {
                    if (code === option.code) {
                        bgcolor = color;
                        fontColor = AppStyle.palette.common.white;
                    }
                });
                return (
                    <>
                        <button
                            {...getOptionProps({ option, index })}
                            style={{
                                backgroundColor: bgcolor,
                                color: "#000",
                                padding: "11px 10px 8px 10px",
                                borderRadius: "5px",
                                margin: "2px",
                            }}
                            className="font-regular use-case__list-item"
                            key={index}
                        >
                            {option?.name}
                        </button>
                    </>
                );
            })}
        </div>
    );
};

export const AutoCompleteSelect = ({ props }) => {
    let {
        headerName,
        multiSelectInputList,
        handleChange,
        setCityList,
        setSelectedList,
        selectedList,
        list,
        isValid,
        searchIsTouched,
    } = props;

    let {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        focused,

        setAnchorEl,
    } = useAutocomplete({
        id: "customized-hook-demo",
        multiple: true,
        filterSelectedOptions: true,
        onChange: (e, option, reason, details) => {
            if (reason === "selectOption") {
                let isValueExists =
                    selectedList &&
                    selectedList.length > 0 &&
                    selectedList.find(({ id }) => {
                        if (id === details.option.id) {
                            return id;
                        }
                    }, details);
                if (!isValueExists) {
                    setSelectedList(option);
                } else {
                    return false;
                }
            } else {
                const updatedList =
                    selectedList &&
                    selectedList.length > 0 &&
                    selectedList.filter(({ id }) => id !== details.option.id);
                setSelectedList(updatedList);
            }
            setCityList([]);
        },
        options: multiSelectInputList && multiSelectInputList,
        value: selectedList || [],
        //defaultValue:selectedList||[],
        getOptionLabel: ({ name }) => name,
    });

    const filteredGroupedOptions = groupedOptions.filter((option) => {
        // Check if the option's name is not in selectedList
        return !selectedList.some((selected) => selected.name === option.name);
    });

    return (
        <div className="tag autocompleteInput" {...getRootProps()}>
            <Label
                {...getInputLabelProps()}
                style={{ color: "#555555" }}
                className={`font-regular ${
                    searchIsTouched && !isValid ? "invalid" : ""
                }`}
            >
                {headerName}
            </Label>
            <InputWrapper
                // onClick={handleClick}
                ref={setAnchorEl}
                className={`${focused ? "focused" : ""} ${
                    searchIsTouched && !isValid ? "invalid" : ""
                }`}
            >
                {selectedList &&
                    selectedList?.length > 0 &&
                    selectedList.map(({ color, name, id }, index) => {
                        return (
                            <StyledTag
                                color={color}
                                label={name}
                                id={id}
                                key={id}
                                {...getTagProps({ index })}
                            />
                        );
                    })}
                <input onKeyUp={handleChange} {...getInputProps()} />
            </InputWrapper>
            {filteredGroupedOptions && filteredGroupedOptions?.length > 0 && (
                <Listbox {...getListboxProps()}>
                    {list ? (
                        filteredGroupedOptions.map((option, index) => {
                            return (
                                <li
                                    {...getOptionProps({ option, index })}
                                    className="autoComplete__list-item"
                                    key={option.name}
                                >
                                    {option?.name}
                                </li>
                            );
                        })
                    ) : (
                        <Stack>
                            <AutoCompleteTabList
                                selectedList={selectedList}
                                getOptionProps={getOptionProps}
                                groupedOptions={filteredGroupedOptions}
                                fromIndex={0}
                                toIndex={1}
                            />
                            <h4
                                className="font-black"
                                style={{
                                    color: "#000",
                                    fontSize: "14px",
                                    margin: "20px 0 2px 0",
                                }}
                            >
                                MULTI-FAMILY
                            </h4>
                            <AutoCompleteTabList
                                selectedList={selectedList}
                                getOptionProps={getOptionProps}
                                groupedOptions={groupedOptions}
                                fromIndex={1}
                                toIndex={4}
                            />
                            <AutoCompleteTabList
                                selectedList={selectedList}
                                getOptionProps={getOptionProps}
                                groupedOptions={groupedOptions}
                                fromIndex={4}
                                toIndex={7}
                            />
                        </Stack>
                    )}
                </Listbox>
            )}
        </div>
    );
};
