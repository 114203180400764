import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { React, useState, useEffect, useContext, useLayoutEffect } from "react";
import { AutoCompleteSelect, updateLocalStorage } from "shared/utils";
import { disableStyle, searchContainter, card1style } from "app";

import { getUseCaseList } from "server/api/get-usecase-list";
import { LoadingContext } from "store/loading-context-provider";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import { useUser } from "@clerk/clerk-react";
import cityWithGeoId from "./v1-states.json";
import { getStripeSubscriptionActive } from "server/api/stripe";

const SearchSection = () => {
  const navigate = useNavigate();
  const {
    loaderFunction,
    searchTitleGetterSetter,
    userGetterSetter,
    searchCriteria,
    tableActive,
    setCityList,
    cityList,
    selectedUseCaseList,
    selectedCityList,
    searchFunction,
    setSelectedCityList,
    setSelectedUseCaseList,
    setSearchedReasult,
    setCityNameResultList,
    setXreSearchDisable,
    xreaSeachButtonTitle,
    setXreaTableRows,
    setSearchCriteria,
    handleSpecificSearchResponse,
    savesearchId,
    hasResult,
    setSaveSearchId,
    setUseCaseNameList,
  } = useContext(LoadingContext);
  const { searchTitle, setSearchTitle } = searchTitleGetterSetter;
  const { startLoader, stopLoader } = loaderFunction;

  // const { user } = userGetterSetter;

  const [useCaseList, setUseCaseList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [savedCityList, setSavedCityList] = useState([]);
  const [savedUseCaseList, setSavedUseCaseList] = useState([]);
  const [autocompleteEnabled, setAutocompleteEnabled] = useState();
  const [userId, setUserId] = useState();
  const location = useLocation();
  const [locationSearchTerm, setLocationSearchTerm] = useState("");
  const { id } = location?.state || {};
  const SearchCriteria = {
    searchId: id,
  };
  const { isSignedIn, user, isLoaded } = useUser();
  const nameList = cityWithGeoId.data.city_view_2021;

  const [searchIsTouched, setSearchIsTouched] = useState(false);
  const [locationIsValid, setLocationIsValid] = useState(false);
  const [useCaseIsValid, setUseCaseIsValid] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);

  useEffect(() => {
    if (SearchCriteria?.searchId) {
      handleSpecificSearchResponse(SearchCriteria?.searchId, "GUEST");
    }
    if (savesearchId) {
      handleSpecificSearchResponse(savesearchId, "USER");
    }
    return () => {
      setSaveSearchId();
    };
  }, [savesearchId]);
  useEffect(() => {
    let isMounted = true;
    (async () => {
      const data = await getUseCaseList();
      if (isMounted) setUseCaseList(data);
      if (searchCriteria?.city) {
        setSelectedCityList(searchCriteria?.city);
        setSavedCityList(searchCriteria?.city);
        if (searchCriteria?.useCase) {
          setSelectedUseCaseList(searchCriteria.useCase);
          setSavedUseCaseList(searchCriteria.useCase);
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [searchCriteria]);

  useEffect(() => {
    if (isSignedIn) {
      const payLoad = {
        clerkUserId: user.id,
      };
      let response = getStripeSubscriptionActive(payLoad).then((res) => {
        setSubscriptionActive(res);
        if (res) {
          setDisabled(false);
        }
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (searchCriteria?.city && selectedCityList?.length > 0) {
      updateLocalStorage("xrea", { isdisabled: true });
    }
  }, [selectedCityList]);
  useEffect(() => {
    if (!isSignedIn || !subscriptionActive) {
      setDisabled(true);
    }

    if (isLoaded && isSignedIn && subscriptionActive) {
      enableAutoComplete();
    } else {
      disableAutoComplete();
    }
  }, [isLoaded, isSignedIn, user, subscriptionActive]);

  const enableAutoComplete = () => {
    setAutocompleteEnabled(true);
  };
  const disableAutoComplete = () => {
    setAutocompleteEnabled(false);
  };
  const handleChangeCity = async (e) => {
    const { value } = e.currentTarget;
    setLocationSearchTerm(value);
    if (locationSearchTerm.length >= 2) {
      const currentFilteredOptions = nameList.filter(
        (city) =>
          city.city_name.toLowerCase().indexOf(value.toLowerCase()) > -1 &&
          filterStateIfApplicable(city)
      );
      const data = currentFilteredOptions.map((city) => {
        return {
          name: city.city_name + ", " + city.state_name,
          id: city.geo_id,
        };
      });
      setLocationIsValid(true);

      setCityList(data);
    }
  };
  const handleUseCaseChange = () => {
    setUseCaseIsValid(true);
  };
  const filterStateIfApplicable = (city) => {
    if (selectedCityList.length > 0) {
      const selectedState = selectedCityList[0].name;
      return selectedState.includes(city.state_name);
    }
    return true;
  };

  let autoCompleteSelectPropsCity = {
    headerName: "Location (city, state)",
    tableActive,
    multiSelectInputList: cityList,
    setCityList: setCityList,
    setSelectedList: setSelectedCityList,
    selectedList: selectedCityList,
    savedList: savedCityList,
    list: true,
    isValid: locationIsValid,
    searchIsTouched: searchIsTouched,
    handleChange: handleChangeCity,
  };
  let autoCompleteSelectPropsUseCase = {
    headerName: "Use case",
    multiSelectInputList: useCaseList,
    setCityList: setCityList,
    setSelectedList: setSelectedUseCaseList,
    selectedList: selectedUseCaseList,
    savedList: savedUseCaseList,
    list: false,
    handleChange: handleUseCaseChange,
  };

  const handleClickOnSearch = () => {
    if (xreaSeachButtonTitle == "Save search") {
      setXreSearchDisable(false);
    }
    setSearchIsTouched(true);
    if (!autoCompleteSelectPropsCity.selectedList.length) {
      setLocationIsValid(false);
    } else {
      setLocationIsValid(true);
    }

    if (!locationIsValid) {
      return;
    }
    if (selectedCityList.length > 0) {
      startLoader();
      searchFunction();
      setSearchTitle();
    }
  };
  return (
    <div style={card1style} className="rounded-xl bg-white">
      <Box sx={searchContainter}>
        <Grid container spacing={1}>
          <Grid item xs={5.25} sx={autocompleteEnabled ? null : disableStyle}>
            <AutoCompleteSelect props={autoCompleteSelectPropsCity} />
          </Grid>
          <Grid item xs={5.25} sx={autocompleteEnabled ? null : disableStyle}>
            <AutoCompleteSelect props={autoCompleteSelectPropsUseCase} />
          </Grid>
          <Grid item xs={1.5}>
           <button className="bg-[#0A49AE] text-white px-8 py-4 mt-6 text-md rounded-xl disabled:bg-gray-200" 
            disabled={disabled}
            onClick={handleClickOnSearch}>Search</button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SearchSection;
